import { useRifm } from 'rifm';
import { DateTime } from 'luxon';

import TextField from 'components/shared/TextField';
import { dateInputFormat, parseDigits } from 'helpers/utils';

interface Props {
  name: string;
  label: string;
  value: string;
  error?: boolean;
  errorText?: string;
  setFieldValue: (field: string, value: string) => void;
  onBlur: (e: React.ChangeEvent<any>) => void;
  fullWidth?: boolean;
}

/**
 * Do some extra work to make sure that the date is formatted correctly and
 * seems to be a valid date
 *
 * For example: 88-88-8888 looks fine, but 88 and 88 are not valid month or day values
 */
export const dateValidation = (value = '') => {
  const d = DateTime.fromFormat(value, dateInputFormat);
  const isValidDate = d.isValid && value.length === 10;

  return isValidDate;
};

const formatDate = (date: string): string => {
  const digits = parseDigits(date);
  const chars = digits.split('');

  return chars
    .reduce(
      (prev, current, index) =>
        index === 2 || index === 4 ? `${prev}/${current}` : `${prev}${current}`,
      '',
    )
    .substring(0, 10);
};

const formatDateWithAppend = (date: string): string => {
  const formatted = formatDate(date);

  if (date.endsWith('/')) {
    if (formatted.length === 2 || formatted.length === 5) {
      return `${formatted}/`;
    }
  }

  return formatted;
};

const appendSlash = (value: string): string =>
  value.length === 2 || value.length === 5 ? `${value}/` : value;

const DateInput: React.FC<Props> = ({
  name,
  label,
  value,
  error,
  errorText,
  setFieldValue,
  onBlur,
  fullWidth,
}) => {
  const rifm = useRifm({
    value: value.replace(/-/g, '/'), // Convert incoming value to display format (MM/DD/YYYY)
    onChange: (value: string) => {
      // Convert display format back to storage format before saving (MM-DD-YYYY)
      setFieldValue(name, value.replace(/\//g, '-'));
    },
    mask: 10 <= value.length,
    format: formatDateWithAppend,
    append: appendSlash,
  });

  return (
    <TextField
      type="tel"
      pattern="[0-9\/]*"
      name={name}
      label={label}
      value={rifm.value}
      error={error}
      errorText={errorText}
      onChange={rifm.onChange}
      onBlur={onBlur}
      fullWidth={fullWidth}
    />
  );
};

export default DateInput;
