import { Elements, StripeProvider } from 'react-stripe-elements';

import {
  CheckoutPaymentMethods,
  PaymentMode,
} from 'components/CheckoutPaymentMethods';
import PageLoader from 'components/PageLoader';
import StripeInstallment from 'components/StripeInstallment';
import config from 'config';
import useApplicationStatus from 'hooks/useApplicationStatus';
import { useProcessInstallmentPayment } from 'hooks/mutations/useProcessInstallmentPayment';

interface InstallmentsPaymentProps {
  /**
   * If true, the Apple Pay and Google Pay buttons will be disabled.
   * This is only used for unit testing.
   */
  walletsDisabled?: boolean;
}

const InstallmentPayment: React.FC<InstallmentsPaymentProps> = ({
  walletsDisabled,
}) => {
  const { data: applicationStatusData, isLoading } = useApplicationStatus();

  const { mutateAsync: handleConfirmPaymentMethod } =
    useProcessInstallmentPayment();

  if (isLoading) {
    return <PageLoader />;
  }

  if (
    applicationStatusData?.applicationDetails?.billingProvider !== 'checkout' &&
    applicationStatusData?.applicationDetails?.provider !== 'checkout'
  ) {
    return (
      <StripeProvider apiKey={config.STRIPE_KEY}>
        <Elements>
          <StripeInstallment />
        </Elements>
      </StripeProvider>
    );
  }

  return (
    <CheckoutPaymentMethods
      paymentMode={PaymentMode.PAYMENT}
      amountInDollars={10}
      onConfirmPaymentMethod={handleConfirmPaymentMethod}
      walletsDisabled={walletsDisabled}
    />
  );
};

export default InstallmentPayment;
