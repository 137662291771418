import { sendMessageToMobileApp } from 'helpers/mobile-app';
import { rollbar, rollbarEnabled } from 'helpers/rollbar';
import { convertPhoneNumberToE164 } from 'helpers/utils';
import posthog, { Properties } from 'posthog-js';
import {
  CHARGE_ACCOUNT_PRODUCT_ID,
  DigitalServiceId,
  INSTALLMENTS_PRODUCT_ID,
  ProductTypes,
  User,
} from 'types/schemas';
import getCurrentPlatform from './signupHelpers/getCurrentPlatform';

const USER_ANALYTICS_EVENTS = [
  'email.submission.succeeded',
  'email.verification.succeeded',
  'phone.submission.succeeded',
  'phone.verification.succeeded',
  'login.submission.succeeded',
] as const;
type UserAnalyticsEvent = typeof USER_ANALYTICS_EVENTS[number];

type StandardTrackingEventAttributes = {
  loanProductId?:
    | typeof INSTALLMENTS_PRODUCT_ID
    | typeof CHARGE_ACCOUNT_PRODUCT_ID; // The product ID on the account or application
  digitalServiceId: DigitalServiceId; // The digital service ID on the account or application
  accountType?: ProductTypes; // The account type on the account or application
};

const APPLICATION_ANALYTICS_EVENTS = [
  'esign.agreed',
  'submission.succeeded',
  'upfrontpayment.succeeded',
] as const;
type ApplicationAnalyticsEvent = typeof APPLICATION_ANALYTICS_EVENTS[number];
type ApplicationAttributes = StandardTrackingEventAttributes;

const PURCHASE_ANALYTICS_EVENTS = ['submission.succeeded'] as const;
type PurchaseAnalyticsEvent = typeof PURCHASE_ANALYTICS_EVENTS[number];
// service_1: course bundle 1 and ID monitoring
// service_2: course bundle 2 and IDT resolution/insurance
type PurchaseAttributes = StandardTrackingEventAttributes;

const UI_ANALYTICS_EVENTS = [
  'page.viewed',
  'forgot-password.clicked',
  'forgot-email.clicked',
  'signup.clicked',
  'signup.privacy-policy.clicked',
  'signup.terms-of-use.clicked',
  'signup.e-sign-consent.clicked',
  'signup.terms-and-conditions.checked',
  'resend-code.clicked',
  'signup.verify-phone-number-go-back.clicked',
  'apply.ssn-disclosure.checked',
  'apply.upfront-payment.clicked',
  'address-input.invalid-address.shown',
  'address-input.recommended-address.shown',
  'esign.reviewed',
  'product-benefits-table.view-more.clicked',
  'footer.get-in-touch.clicked',
  'referral.welcome-modal.seen',
  'referral.share-link.clicked',
  'referral.maybe-later.clicked',
  'app-download.welcome-modal.seen',
  'app-download.maybe-later.clicked',
  'header.referral-button.clicked',
  'settings.update-email.clicked',
  'settings.update-phone-number.clicked',
  'settings.update-address.clicked',
  'settings.update-password.clicked',
  'offers.offer-card.visit-site.clicked',
  'offers.offer-card.disclosure.clicked',
  'offers.offer-categories-list.category.clicked',
  'login.clicked',
  'logout.clicked',
  'payment.update-payment-method.clicked',
  'payment.view-invoice.clicked',
] as const;
type UIAnalyticsEvent = typeof UI_ANALYTICS_EVENTS[number];

type CommonAnalyticsParams = {
  properties?: Properties | null;
  /**
   * If true, the event will be sent as a conversion event to the mobile app.
   * The event will then be sent to Singular.
   * There is an environment check on the mobile app to ensure the event is only
   * sent in production.
   *
   * @default false
   * @type {boolean}
   */
  sendAsConversionEventToMobileApp?: boolean;
  attributes?: Object;
  user?: User;
};

export type TrackProductAnalyticsParams =
  | (CommonAnalyticsParams & {
      namespace: 'user';
      event: UserAnalyticsEvent;
    })
  | (CommonAnalyticsParams & {
      namespace: 'application';
      event: ApplicationAnalyticsEvent;
      attributes: ApplicationAttributes;
    })
  | (CommonAnalyticsParams & {
      namespace: 'purchase';
      event: PurchaseAnalyticsEvent;
      attributes: PurchaseAttributes;
    })
  | (CommonAnalyticsParams & {
      namespace: 'ui';
      event: UIAnalyticsEvent;
    });

const generateEventId = () => {
  return `${new Date().getTime()}-${Math.random()
    .toString(36)
    .substring(2, 15)}`;
};

export const trackProductAnalytics = ({
  namespace,
  event,
  attributes,
  properties,
  user,
  sendAsConversionEventToMobileApp,
}: TrackProductAnalyticsParams) => {
  const platform = getCurrentPlatform();
  const eventId = properties?.eventId ?? generateEventId();

  if (attributes) {
    // Merge attributes into properties
    properties = {
      ...properties,
      ...attributes,
    };
  }

  properties = {
    ...properties,
    eventId: eventId,
    value: properties?.value ?? 0,
    currency: properties?.currency ?? 'USD',
  };

  // Log the event to the console for debugging (only in development)
  if (import.meta.env.DEV) {
    console.log(`[🦔 POSTHOG TRACKING]\nweb.${namespace}.${event}`, {
      ...properties,
      platform,
      currentPath: window.location.pathname,
    });
  }

  const eventName = `web.${namespace}.${event}`;

  // Send to Posthog
  posthog.capture(eventName, {
    ...properties,
    platform,
    currentPath: window.location.pathname,
  });

  const gtmEventParameters = {
    ...convertPropertyNamesToSnakeCase(properties ?? {}),
  };

  if (user) {
    gtmEventParameters.user_id = user.userId;
  }

  // Send to GTM
  window.gtag('event', eventName, {
    ...gtmEventParameters,
    platform,
    current_path: window.location.pathname,
  });

  // Send to Singular (in mobile app) if requested
  if (sendAsConversionEventToMobileApp) {
    sendMessageToMobileApp({
      eventType: 'kovo.webapp.track_conversion_event',
      body: {
        eventName: `${namespace}.${event}`,
        attributes: properties ?? {},
      },
    });
  }
};

const convertPropertyNamesToSnakeCase = (properties: Properties) => {
  return Object.entries(properties).reduce<Record<string, any>>(
    (acc, [key, value]) => {
      acc[key.replace(/([A-Z])/g, '_$1').toLowerCase()] = value;
      return acc;
    },
    {},
  );
};

export const identifyCustomer = (
  user: Partial<User>,
  eventId = generateEventId(),
) => {
  const userId = user.userId;

  if (rollbarEnabled && userId) {
    /**
     * rollbar only allows 40 characters for the id
     * the userId is region:uuid or region:k-uuid, which is too long
     * uuid is only 36 char, k- adds 2 for the prefix, so this is fine
     */

    const rollbarUserId = userId.split(':')[1];
    rollbar.configure({
      payload: {
        person: {
          id: rollbarUserId,
        },
      },
    });
  }

  if (userId) {
    window.gtag('set', 'user_id', userId);
    posthog.identify(userId, {
      kovoUserId: userId,
    });
  }

  const address = {
    first_name: user.firstName,
    last_name: user.lastName,
    country: user.country ?? 'US',
    street: user.address,
    city: user.city,
    region: user.state,
    postal_code: user.zipcode,
  };

  window.gtag('set', 'user_data', {
    email_address: user.currentEmail,
    /**
     * Meta and TikTok expect email_address to be defined. Google Ads expects email.
     * See [docs](https://developers.google.com/tag-platform/tag-manager/server-side/ads-setup#code-configuration)
     */
    email: user.currentEmail,
    phone_number: user.phoneNumber
      ? convertPhoneNumberToE164(user.phoneNumber)
      : undefined,
    user_id: userId,
    // Filter out undefined address values
    address: Object.fromEntries(
      Object.entries(address).filter(([, value]) => value !== undefined),
    ),
  });

  // Let GTM know that we've sent information
  window.gtag('event', 'identify', {
    event_id: eventId,
  });
};
