import { LoadingButton } from '@mui/lab';
import { Alert, Box } from '@mui/material';
import { API } from 'aws-amplify';
import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import ContractScrollWrapper from 'components/ContractScrollWrapper';
import EsignContract, {
  CONTRACT_WIDTH,
} from 'components/EsignContract/EsignContract';
import { AuthContext } from 'context/AuthProvider';
import { theme } from 'context/ThemeProvider';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { USE_CURRENT_USER_QUERY_KEY } from 'hooks/queries/useCurrentUser';
import { USE_APPLICATION_STATUS_QUERY_KEY } from 'hooks/queryKeys';
import { KovoError } from 'libs/KovoError';
import { logOnboardingEvent } from 'libs/logger';
import { trackProductAnalytics } from 'libs/productAnalyticsTracking';
import { INSTALLMENTS_PRODUCT_ID } from 'types/schemas';

const InstallmentsContractForm: React.FC = () => {
  const { email, identityId, username } = useContext(AuthContext);

  const [isScrolled, setIsScrolled] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const history = useHistory();

  const queryClient = useQueryClient();

  const onSubmit = () => {
    mutate();
  };

  const saveViewedAt = async () => {
    try {
      await API.post('installments', '/installments/esign-viewed', {
        body: {
          email,
        },
        ...amplifyRequestContext(identityId, username),
      });
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Error saving viewed at', {
        error,
      });
    }
  };

  const onSuccess = async () => {
    const eventName = 'CompleteESign';
    const eventId = `${identityId}-${eventName}-installments10`;

    trackProductAnalytics({
      namespace: 'application',
      event: 'esign.agreed',
      attributes: {
        accountType: 'loan_installment',
        loanProductId: INSTALLMENTS_PRODUCT_ID,
        digitalServiceId: 'service_1',
      },
      sendAsConversionEventToMobileApp: true,
      properties: {
        eventId,
      },
    });

    logOnboardingEvent({ eventName: 'esign completed', email: email! });
    queryClient.invalidateQueries({
      queryKey: [USE_CURRENT_USER_QUERY_KEY],
    });
    queryClient.invalidateQueries({
      queryKey: [USE_APPLICATION_STATUS_QUERY_KEY],
    });
    history.replace('/payment');
  };

  useEffect(() => {
    if (isScrolled) {
      trackProductAnalytics({
        namespace: 'ui',
        event: 'esign.reviewed',
      });
      buttonRef.current?.focus({ preventScroll: false });
    }
  }, [isScrolled]);

  const { mutate, isLoading, error } = useMutation(saveViewedAt, { onSuccess });

  const { handleSubmit } = useFormik({ initialValues: {}, onSubmit });

  return (
    <Box
      sx={{
        marginLeft: theme.spacing(-1.5),
        marginRight: theme.spacing(-1.5),

        [theme.breakpoints.up('sm')]: {
          marginLeft: `calc((${CONTRACT_WIDTH} - 100vw) / 2)`,
          marginRight: `calc((${CONTRACT_WIDTH} - 100vw) / 2)`,
        },

        [theme.breakpoints.up('md')]: {
          marginLeft: theme.spacing(-3),
          marginRight: theme.spacing(-3),
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <Box sx={{ position: 'relative' }}>
          {error && (
            <Alert
              severity="error"
              sx={{ position: 'absolute', width: '100%' }}
            >
              There was an error. Please refresh and try again.
            </Alert>
          )}

          <ContractScrollWrapper
            isScrolled={isScrolled}
            setIsScrolled={setIsScrolled}
          >
            <EsignContract productId="pro_1" />
          </ContractScrollWrapper>
        </Box>

        {isScrolled && (
          <Box
            sx={{
              padding: theme.spacing(0, 1.5),

              [theme.breakpoints.up('sm')]: {
                paddingLeft: `calc((100vw - ${CONTRACT_WIDTH}) / 2)`,
                paddingRight: `calc((100vw - ${CONTRACT_WIDTH}) / 2)`,
              },

              [theme.breakpoints.up('md')]: {
                padding: theme.spacing(0, 3),
              },
            }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
              ref={buttonRef}
              fullWidth
            >
              Agree
            </LoadingButton>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default InstallmentsContractForm;
