import { useState } from 'react';

import AppReviewDialog from 'components/AppReviewDialog';
import useGetReviewEligibility from 'hooks/queries/useGetReviewEligibility';
import LeaveReviewButtonView from './LeaveReviewButtonView';

const LeaveReviewButton: React.FC = () => {
  const { data: reviewEligibilityData } = useGetReviewEligibility();

  const [isOpen, setIsOpen] = useState(false);

  if (!reviewEligibilityData || !reviewEligibilityData.isEligible) {
    return null;
  }

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <AppReviewDialog isOpen={isOpen} onClose={handleClose} />

      <LeaveReviewButtonView handleClick={handleClick} />
    </>
  );
};

export default LeaveReviewButton;
