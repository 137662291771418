import { API } from 'aws-amplify';
import { useAuthContext } from 'context/AuthProvider';
import { BillingService } from 'libs/BillingService';

export const useBillingService = () => {
  const { identityId: userId, username } = useAuthContext();

  return new BillingService(API, userId, username);
};

export default useBillingService;
