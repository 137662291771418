import { API } from 'aws-amplify';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import { AuthContext } from 'context/AuthProvider';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { KovoError } from 'libs/KovoError';

export type CreditScore = {
  score: number;
  source: 'transunion' | 'equifax' | undefined;
  scoreDate: string;
  userId: string;
};

export type CreditScoresMetadata = {
  totalCount: number;
  scoreChange: number;
  scoreChangeIsSignificant: boolean;
  firstScore?: number;
  lastScore?: number;
};

export type GetCreditScoresResponse = {
  creditScores: CreditScore[];
  metadata: CreditScoresMetadata;
};

function useGetCreditScores() {
  const { identityId, username } = useContext(AuthContext);

  const getCreditScores = async () => {
    try {
      const res: GetCreditScoresResponse = await API.get(
        'clientsService',
        `/v2/credit_scores`,
        {
          headers: {
            ...amplifyRequestContext(identityId, username, {
              'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
              'x-user-id': identityId,
            }).headers,
          },
        },
      );

      if (!res.creditScores.length || res.creditScores.length === 1) {
        return res;
      }

      return res;
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Error getting credit scores', { error });
    }
  };

  return useQuery<GetCreditScoresResponse, KovoError>(
    'credit-scores',
    getCreditScores,
    {
      refetchOnWindowFocus: false,
    },
  );
}

export default useGetCreditScores;
