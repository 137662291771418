import { Auth } from 'aws-amplify';
import {
  KovoError,
  KovoUnauthenticatedError,
  KovoUserNotConfirmedError,
} from 'libs/KovoError';
import { AuthErrorCode, AuthResult } from './types';

/**
 * This function will sign in a user with the given email and password.
 */
export const signInUser = async (
  email: string,
  password: string,
): Promise<AuthResult> => {
  try {
    const cognitoUser = await Auth.signIn(email, password);

    return {
      cognitoUser: cognitoUser,
      cognitoUserConfirmed: true,
    };
  } catch (error) {
    if (error instanceof Error) {
      switch (error.name) {
        case 'NotAuthorizedException':
          /**
           * Cognito uses NotAuthorizedException for two use cases. Pre-confirmation, it is thrown
           * regardless of the password being correct because the user is not confirmed. Post-confirmation
           * it is thrown when the password is incorrect. This is super confusing, and the only way to
           * try to deterministically determine the error is to look at the message.
           */
          if (error.message.startsWith('Invalid status UNCONFIRMED')) {
            throw new KovoUserNotConfirmedError(error.message)
              .setError(error)
              .setCode(AuthErrorCode.UNCONFIRMED_EMAIL_EXCEPTION)
              .setLogLevel('warn')
              .addMetadata({ email })
              .exposeMessage();
          }

          throw new KovoUnauthenticatedError(error.message)
            .setError(error)
            .setCode(AuthErrorCode.NOT_AUTHORIZED)
            .setLogLevel('warn')
            .addMetadata({ email })
            .exposeMessage();
        case 'UserNotFoundException':
          throw new KovoUnauthenticatedError(error.message)
            .setError(error)
            .setCode(AuthErrorCode.USER_NOT_FOUND)
            .setLogLevel('warn')
            .addMetadata({ email })
            .exposeMessage();
        case 'UserNotConfirmedException':
          throw new KovoUserNotConfirmedError(error.message)
            .setError(error)
            .setCode(AuthErrorCode.UNCONFIRMED_EMAIL_EXCEPTION)
            .setLogLevel('warn')
            .addMetadata({ email })
            .exposeMessage();
        case 'LimitExceededException':
          throw new KovoUnauthenticatedError(error.message)
            .setError(error)
            .setCode(AuthErrorCode.LIMIT_EXCEEDED)
            .setLogLevel('warn')
            .addMetadata({ email })
            .exposeMessage();
      }
    }

    throw new KovoError('Error signing in').setError(error);
  }
};
