import { KovoAlert } from 'components/KovoAlert';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { theme } from 'context/ThemeProvider';
import { Box, SxProps, Theme } from '@mui/material';
import { KovoAlertSeverity } from 'components/KovoAlert/KovoAlert';

type KovoOutageAlertProps = {
  sx?: SxProps<Theme>;
  message: string;
};

const KovoOutageAlert = ({ sx, message }: KovoOutageAlertProps) => {
  const outageAlertConfig = useFeatureFlagPayload('KOVO_OUTAGE_ALERT') as
    | undefined
    | {
        severity: KovoAlertSeverity;
      };

  if (!outageAlertConfig) {
    return null;
  }

  if (!outageAlertConfig.severity) {
    return null;
  }

  return (
    <Box sx={{ marginBottom: theme.spacing(4), ...sx }}>
      <KovoAlert severity={outageAlertConfig.severity} message={message} />
    </Box>
  );
};

export default KovoOutageAlert;
