import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';

import { useAuthContext } from 'context/AuthProvider';
import { ClientsService } from 'libs/ClientsService';

/**
 * Use a default value for the userId until it is populated
 * by the "me" endpoint (useCurrentUser), or temporarily by the identityId.
 */
const defaultUserIdValue = '';

export const useClientsService = () => {
  const { username: userPoolUserId, identityId } = useAuthContext();

  const [service] = useState(
    new ClientsService(
      API,
      import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
      defaultUserIdValue,
      userPoolUserId,
    ),
  );

  /**
   * Only update the userPoolUserId value when it actually changes.
   *
   * "service" is excluded from the useEffect deps so that useEffect does not rerun
   * when a property of the service singleton updates. When this reruns unnecessarily, it
   * can also trigger wrapping component updates as well.
   */
  useEffect(() => {
    if (service.userPoolUserId !== userPoolUserId) {
      /**
       * If we have not set a userId yet but the identityId is available, use the identityId to
       * maintain some backwards compatibility in the local environment while feature toggles
       * are being enabled. When /me returns, it will correct the userId value to the user's
       * actual userId value no matter if it is a generated userId or if the identityId was used as
       * the userId.
       *
       * See src/hooks/queries/useCurrentUser.ts#L21 for where this userId correction value is set.
       */
      let userId = service.userId === '' && identityId ? identityId : undefined;

      /**
       * Reset userId on logout, otherwise do not update it. If userPoolUserId === '', this is the logged out
       * state.
       */
      userId = userPoolUserId === '' ? '' : undefined;

      service.setHeaderOptions({
        userPoolUserId,
        userId,
      });
    }
  }, [userPoolUserId, identityId]); // eslint-disable-line react-hooks/exhaustive-deps

  return service;
};
