import { ApplicationStatusResponse } from 'context/Profile';
import * as impact from 'helpers/impact';
import { logOnboardingEvent } from 'libs/logger';
import { INSTALLMENTS_PRODUCT_ID, LoanProductId } from 'types/schemas';
import { trackProductAnalytics } from './productAnalyticsTracking';

export const trackConversion = async (
  userId: string,
  username: string,
  email: string,
  productId: LoanProductId,
  applicationStatus: ApplicationStatusResponse,
) => {
  if (productId === INSTALLMENTS_PRODUCT_ID) {
    const eventName = 'purchase';
    const eventId = `${userId}-${eventName}-installments10`;

    trackProductAnalytics({
      namespace: 'purchase',
      event: 'submission.succeeded',
      attributes: {
        accountType: 'loan_installment',
        loanProductId: INSTALLMENTS_PRODUCT_ID,
        digitalServiceId: 'service_1',
      },
      properties: {
        eventId,
      },
      sendAsConversionEventToMobileApp: true,
    });
  }

  // Trigger Impact "Initial Installment Payment" action
  impact.trackInitialPayment({
    customerId: username,
    customerEmail: email,
  });

  logOnboardingEvent({ eventName: 'payment completed', email: email! });
};
