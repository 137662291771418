import { API } from 'aws-amplify';
import { AuthContext } from 'context/AuthProvider';
import { KovoError } from 'libs/KovoError';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import { amplifyRequestContext } from 'helpers/amplify-request-context';

export interface ReviewEligibilityResponse {
  isEligible: boolean;
}

function useGetReviewEligibility() {
  const { identityId, username } = useContext(AuthContext);

  const getReviewEligibility = async () => {
    try {
      const res = await API.get('clientsService', '/v1/reviews/eligible', {
        headers: {
          ...amplifyRequestContext(identityId, username, {
            'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
            'x-user-id': identityId,
          }).headers,
        },
      });

      return res;
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Failed to fetch reviews', { error });
    }
  };

  return useQuery<ReviewEligibilityResponse>(
    'isEligibleForReview',
    getReviewEligibility,
  );
}

export default useGetReviewEligibility;
