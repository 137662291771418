import { sendMessageToMobileApp } from 'helpers/mobile-app';
import useCreateReview from 'hooks/mutations/useCreateReview';
import AppReviewDialogView from './AppReviewDialogView';

export interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const WALLETHUB_URL = 'https://wallethub.com/profile/kovo-70785112i';
const FEEDBACK_SURVEY_URL =
  'https://forms.reform.app/kovo/customer-feedback/xrp713';

const AppReviewDialog: React.FC<Props> = ({ isOpen, onClose }) => {
  const { mutate, isLoading } = useCreateReview();

  const handleClick = async (rating: 0 | 1) => {
    mutate({
      ratingType: 'binary',
      rating,
      type: 'in_app',
      platform: 'web',
    });

    if (rating === 1) {
      if (window.ReactNativeWebView) {
        sendMessageToMobileApp({
          eventType: 'kovo.webapp.click.rate_us',
        });
      } else {
        window?.open(WALLETHUB_URL, '_blank')?.focus();
      }
    } else if (rating === 0) {
      if (window.ReactNativeWebView) {
        sendMessageToMobileApp({
          eventType: 'kovo.webapp.click.feedback_survey',
        });
      } else {
        window?.open(FEEDBACK_SURVEY_URL, '_blank')?.focus();
      }
    }
  };

  return (
    <AppReviewDialogView
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onClose}
      handleClick={handleClick}
    />
  );
};

export default AppReviewDialog;
