import { CognitoUser } from '@aws-amplify/auth';
import config from 'config';
import { useAuthContext, UserAuthDetails } from 'context/AuthProvider';
import { ClientsService } from 'libs/ClientsService';
import ReactGA from 'react-ga';
import { User } from 'types/schemas';
import { USE_CURRENT_USER_QUERY_KEY } from './queries/useCurrentUser';

import { ROUTES } from 'components/Routes';
import { sendMessageToMobileApp } from 'helpers/mobile-app';
import { KovoError, KovoUserCreationError } from 'libs/KovoError';
import { logOnboardingEvent } from 'libs/logger';
import { trackProductAnalytics } from 'libs/productAnalyticsTracking';
import { createUserRecord } from 'libs/signupHelpers';
import { getCognitoUserAuthDetails } from 'libs/signupHelpers/getCognitoUserAuthDetails';
import { useCallback } from 'react';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

export type HandleSignupResponse = {
  cognitoUser: CognitoUser;
  userDetails: UserAuthDetails;
  user: User;
};

export interface HandlePostSignupOptions {
  cognitoUser: CognitoUser;
  clientsService: ClientsService;
}

type UserHandlePostSignupOptions = Omit<
  UseMutationOptions<
    HandleSignupResponse,
    KovoUserCreationError,
    HandlePostSignupOptions
  >,
  'onSuccess' | 'mutationFn'
>;

export const MUTATE_HANDLE_POST_USER_SIGNUP_KEY = 'mutateHandlePostUserSignup';

export const useHandlePostUserSignup = (
  options: UserHandlePostSignupOptions = {},
) => {
  const { authChangeCallback } = useAuthContext();
  const history = useHistory();
  const queryClient = useQueryClient();

  const handlePostUserSignup = useCallback(
    async (options: HandlePostSignupOptions): Promise<HandleSignupResponse> => {
      try {
        const { cognitoUser, clientsService } = options;

        const userDetails = await getCognitoUserAuthDetails(cognitoUser);

        const user = await createUserRecord(clientsService, {
          email: userDetails.email,
          userId: userDetails.identityId,
          userPoolUserId: userDetails.username,
        });

        /**
         * Normally we would invalidate queries and let the query rerun. However in this case
         * createUserRecord not only returns a User record, but also calls getCurrentUser to
         * do so. So just update the data stored in react-query's cache instead of forcing another
         * network call.
         */
        queryClient.setQueryData(USE_CURRENT_USER_QUERY_KEY, user);

        const eventName = 'CompleteEmailVerification';
        const eventId = `${user.userId}-${eventName}-installments10`;

        trackProductAnalytics({
          namespace: 'user',
          event: 'email.verification.succeeded',
          sendAsConversionEventToMobileApp: true,
          properties: {
            eventId,
          },
        });

        if (config.VITE_STAGE === 'prod') {
          ReactGA.event({
            category: 'User',
            action: eventName,
          });
        }

        logOnboardingEvent({
          eventName: 'email verified',
          email: user.currentEmail,
        });

        authChangeCallback(cognitoUser, userDetails, undefined, true);

        /**
         * Send message to mobile app letting it know that the user has been verified
         */
        if (window.ReactNativeWebView) {
          sendMessageToMobileApp({
            eventType: 'kovo.webapp.complete.email_verification',
          });
        }

        return { cognitoUser, userDetails, user };
      } catch (error) {
        if (error instanceof KovoError) {
          throw error;
        }

        throw new KovoUserCreationError('Error during handlePostUserSignup', {
          error,
        }).setDisplayMessage('Error signing up. Please try again.');
      }
    },
    [queryClient, authChangeCallback],
  );

  return useMutation<
    HandleSignupResponse,
    KovoUserCreationError,
    HandlePostSignupOptions
  >({
    mutationKey: MUTATE_HANDLE_POST_USER_SIGNUP_KEY,
    ...options,
    mutationFn: handlePostUserSignup,
    onSuccess() {
      history.push(ROUTES.PHONE_NUMBER);
    },
  });
};
